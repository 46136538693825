





























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    ViewDataEstructuraBasica: () =>
      import(
        "@/views/entrevista/dieta-visualizacion/view_data_estructura_basica.vue"
      ),
  },
})
export default class ViewDataAlimento extends Vue {
  @PropSync("plato", { type: Object }) syncedplato!: dieta_visualizacion;

  public created() {
    alimentoModule.getalimentoficha(this.syncedplato.id_estructura);
  }

  public get alimento() {
    return alimentoModule.alimento_ficha;
  }

  public edit_alimento() {
    this.$router.push({
      name: RouterNames.alimentoficha,
      params: { id: this.alimento.id_estructura.toString() },
    });
  }
}
